import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardSidebarComponent } from './components/dashboard-sidebar/dashboard-sidebar.component';
import { AuthGuard } from './guards/auth.guard';
import { Role } from './_models/role';
import { Permissions } from './constants';
import {
  PlantationShutterProductionInstallModule
} from "./pages/plantation-shutter/plantation-shutter-production-install/plantation-shutter-production-install.module";
import {
  PlantationShutterProductionOpenModule
} from "./pages/plantation-shutter/plantation-shutter-production-open/plantation-shutter-production-open.module";
const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./authentication/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'password',
    loadChildren: () =>
      import('./authentication/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: '',
    component: DashboardSidebarComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        data: { roles: [Role.Sales, Role.Admin, Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'dashboard',
        data: { roles: [Role.Sales, Role.Admin, Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'bid',
        data: { roles: [Role.Sales, Role.Admin, Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import('./pages/bid/bid.module').then((m) => m.BidModule),
      },
      {
        path: 'wholesaler-settings',
        data: { roles: [Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import('./pages/wholesaler-settings/wholesaler-settings.module').then((m) => m.WholesalerSettingsModule),
      },
      
      {
        path: 'customer',
        data: { roles: [Role.Admin], permissions: [] },
        loadChildren: () =>
          import('./pages/customer/customer.module').then(
            (m) => m.CustomerModule
          ),
      },
      {
        path: 'sales-person',
        data: { roles: [Role.Admin], permissions: [] },
        loadChildren: () =>
          import('./pages/sales-person/sales-person.module').then(
            (m) => m.SalesPersonModule
          ),
      },
      {
        path: 'window-size',
        data: {
          roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings]
        },
        loadChildren: () =>
          import('./pages/window-size/window-size.module').then(
            (m) => m.WindowSizeModule
          ),
      },
      {
        path: 'solar-addon',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/solar-addon/solar-addon.module').then(
            (m) => m.SolarAddonModule
          ),
      },
      {
        path: 'preview-customer',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/preview-customer/preview-customer.module').then(
            (m) => m.PreviewCustomerModule
          ),
      },
      {
        path: 'profit-percentage',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/profit-percentage/profit-percentage.module').then(
            (m) => m.ProfitPercentageModule
          ),
      },
      {
        path: 'wholesaler-profit-percentage',
        data: {roles: [Role.Sales, Role.Admin], permissions:[Permissions.view_settings]},
        loadChildren: () =>
          import('./pages/wholesaler-profit-percentage/wholesaler-profit-percentage.module').then(
            (m) => m.WholesalerProfitPercentageModule
          ),
      },
      {
        path: 'jobs/roller-shade',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [Permissions.view_jobs_in_progress] },
        loadChildren: () =>
          import('./pages/job-progress/job-progress.module').then(
            (m) => m.JobProgressModule
          ),
      },
      {
        path: 'jobs/epoxy-garage',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [Permissions.view_jobs_in_progress] },
        loadChildren: () =>
          import('./pages/job-progress/job-progress.module').then(
            (m) => m.JobProgressModule
          ),
      },
      {
        path: 'jobs/carpet-installation',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [Permissions.view_jobs_in_progress] },
        loadChildren: () =>
          import('./pages/job-progress/job-progress.module').then(
            (m) => m.JobProgressModule
          ),
      },
      {
        path: 'jobs/plantation-shutter',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [Permissions.view_jobs_in_progress] },
        loadChildren: () =>
          import('./pages/job-progress/job-progress.module').then(
            (m) => m.JobProgressModule
          ),
      },
      {
        path: 'jobs/blinds',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [Permissions.view_jobs_in_progress] },
        loadChildren: () =>
          import('./pages/job-progress/job-progress.module').then(
            (m) => m.JobProgressModule
          ),
      },
      {
        path: 'jobs/custom-roller-shade',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [Permissions.view_jobs_in_progress] },
        loadChildren: () =>
          import('./pages/job-progress/job-progress.module').then(
            (m) => m.JobProgressModule
          ),
      },
      {
        path: 'jobs/perfect-sheer-shade',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [Permissions.view_jobs_in_progress] },
        loadChildren: () =>
          import('./pages/job-progress/job-progress.module').then(
            (m) => m.JobProgressModule
          ),
      },
      {
        path: 'jobs/custom-roman-shade',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [Permissions.view_jobs_in_progress] },
        loadChildren: () =>
          import('./pages/job-progress/job-progress.module').then(
            (m) => m.JobProgressModule
          ),
      },
      {
        path: 'jobs/honeycomb-shade',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [Permissions.view_jobs_in_progress] },
        loadChildren: () =>
          import('./pages/job-progress/job-progress.module').then(
            (m) => m.JobProgressModule
          ),
      },
      {
        path: 'jobs/engineered-floor',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [Permissions.view_jobs_in_progress] },
        loadChildren: () =>
          import('./pages/job-progress/job-progress.module').then(
            (m) => m.JobProgressModule
          ),
      },
      {
        path: 'jobs/windows-replacement',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [Permissions.view_jobs_in_progress] },
        loadChildren: () =>
          import('./pages/job-progress/job-progress.module').then(
            (m) => m.JobProgressModule
          ),
      },
      {
        path: 'payment',
        data: { roles: [Role.Admin, Role.Sales, Role.Wholesaler], permissions: [Permissions.take_payment] },
        loadChildren: () =>
          import('./pages/payment/payment.module').then((m) => m.PaymentModule),
      },
      {
        path: 'roller-shade-tier-product',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/roller-shade/roller-shade-tier-products/roller-shade-tier-products.module'
          ).then((m) => m.RollerShadeTierProductsModule),
      },
      {
        path: 'roller-shade-tier-pricing',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/roller-shade/roller-shade-tier-pricing/roller-shade-tier-pricing.module'
          ).then((m) => m.RollerShadeTierPricingModule),
      },
      {
        path: 'roller-shade-tier-list',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/roller-shade/roller-shade-tier-list/roller-shade-tier-list.module'
          ).then((m) => m.RollerShadeTierListModule),
      },
      {
        path: 'roller-shade-addon',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/roller-shade/roller-shade-addon/roller-shade-addon.module'
          ).then((m) => m.RollerShadeAddonModule),
      },
      {
        path: 'roller-shade-quote',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/roller-shade/roller-shade-quote/roller-shade-quote.module'
          ).then((m) => m.RollerShadeQuoteModule),
      },
      {
        path: 'roller-shade-contract',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/roller-shade/roller-shade-contract/roller-shade-contract.module'
          ).then((m) => m.RollerShadeContractModule),
      },
      {
        path: 'roller-shade-commission',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/quote-commission/create-quote-commission/quote-commission.module'
          ).then((m) => m.QuoteCommissionModule),
      },
      {
        path: 'additional-quote-commission',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/additional-commission/create-additional-quote-commission/additional-quote-commission.module'
          ).then((m) => m.AdditionalQuoteCommissionModule),
      },
      {
        path: 'additional-floor-commission',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/additional-commission/create-additional-floor-commission/additional-floor-commission.module'
          ).then((m) => m.AdditionalFloorCommissionModule),
      },
      {
        path: 'epoxy-garage-quote',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/epoxy-garage/epoxy-garage-quote/epoxy-garage-quote.module'
          ).then((m) => m.EpoxyGarageQuoteModule),
      },
      {
        path: 'epoxy-garage-contract',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/epoxy-garage/epoxy-garage-contract/epoxygaragecontract.module'
          ).then((m) => m.EpoxygaragecontractModule),
      },
      {
        path: 'epoxy-garage-commission',
        data: { roles: [Role.Sales, Role.Admin], permissions: [] },
        loadChildren: () =>
          import(
            './pages/quote-commission/create-floor-commission/floor-commission.module'
          ).then((m) => m.FloorCommissionModule),
      },
      {
        path: 'carpet-installation-addon',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/carpet-installation/carpet-installation-addon/carpet-installation-addon.module'
          ).then((m) => m.CarpetInstallationAddonModule),
      },
      {
        path: 'carpet-installation-quote',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/carpet-installation/carpet-installation-quote/carpet-installation-quote.module'
          ).then((m) => m.CarpetInstallationQuoteModule),
      },
      {
        path: 'carpet-installation-contract',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/carpet-installation/carpet-installation-contract/carpet-installation-contract.module'
          ).then((m) => m.CarpetInstallationContractModule),
      },
      {
        path: 'carpet-installation-commission',
        data: { roles: [Role.Sales, Role.Admin], permissions: [] },
        loadChildren: () =>
          import(
            './pages/quote-commission/create-floor-commission/floor-commission.module'
          ).then((m) => m.FloorCommissionModule),
      },
      {
        path: 'plantation-shutter-product-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-product/plantation-shutter-product.module'
          ).then((m) => m.PlantationShutterProductModule),
      },
      {
        path: 'plantation-shutter-extension-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-extension/plantation-shutter-extension.module'
          ).then((m) => m.PlantationShutterExtensionModule),
      },
      {
        path: 'plantation-shutter-general-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-general/plantation-shutter-general.module'
          ).then((m) => m.PlantationShutterGeneralModule),
      },
      {
        path: 'honeycomb-shutter-general-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-general/plantation-shutter-general.module'
          ).then((m) => m.PlantationShutterGeneralModule),
      },
      {
        path: 'measurement-image-gallery',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/measurementgallery/measurementgallery.module').then(
            (m) => m.MeasurementgalleryModule
          ),
      },
      {
        path: 'engineered-shutter-general-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-general/plantation-shutter-general.module'
          ).then((m) => m.PlantationShutterGeneralModule),
      },
      {
        path: 'plantation-shutter-quote',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-quote/plantation-shutter-quote.module'
          ).then((m) => m.PlantationShutterQuoteModule),
      },
      {
        path: 'plantation-shutter-contract',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-contract/plantation-shutter-contract.module'
          ).then((m) => m.PlantationShutterContractModule),
      },
      {
        path: 'plantation-shutter-material',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-materials/plantation-shutter-materials.module'
          ).then((m) => m.PlantationShutterMaterialsModule),
      },
      // {
      //   path: 'plantation-shutter-measuresheet',
      //   data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.measure_cut_sheets] },
      //   loadChildren: () =>
      //     import(
      //       './pages/plantation-shutter/plantation-shutter-measure-sheet/plantation-shutter-measure-sheet.module'
      //     ).then((m) => m.PlantationShutterMeasureSheetModule),
      // },
      {
        path: 'plantation-shutter-cutsheet',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [Permissions.measure_cut_sheets] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-cut-sheet/plantation-shutter-cut-sheet.module'
          ).then((m) => m.PlantationShutterCutSheetModule),
      },
      {
        path: 'plantation-shutter-production-open',
        data: { roles: [Role.Sales, Role.Admin], permissions: [] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-production-open/plantation-shutter-production-open.module'
            ).then((m) => m.PlantationShutterProductionOpenModule),
      },
      {
        path: 'plantation-shutter-production-install',
        data: { roles: [Role.Sales, Role.Admin], permissions: [] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-production-install/plantation-shutter-production-install.module'
            ).then((m) => m.PlantationShutterProductionInstallModule),
      },
      {
        path: 'blind-pricing-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/blind-pricing/blind-pricing-setting/blind-pricing-setting.module'
          ).then((m) => m.BlindPricingSettingModule),
      },
      {
        path: 'blind-quote',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/blind-pricing/blind-pricing-quote/blind-pricing-quote.module'
          ).then((m) => m.BlindPricingQuoteModule),
      },
      {
        path: 'blind-contract',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/blind-pricing/blind-pricing-contract/blind-pricing-contract.module'
          ).then((m) => m.BlindPricingContractModule),
      },
      {
        path: 'blind-commission',
        data: { roles: [Role.Sales, Role.Admin], permissions: [] },
        loadChildren: () =>
          import(
            './pages/quote-commission/create-quote-commission/quote-commission.module'
          ).then((m) => m.QuoteCommissionModule),
      },
      {
        path: 'custom-roller-shade-pricing-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/custom-roller-shade/custom-roller-shade-pricing-setting/custom-roller-shade-pricing-setting.module'
          ).then((m) => m.CustomRollerShadePricingSettingModule),
      },
      {
        path: 'custom-roller-shade-quote',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/custom-roller-shade/custom-roller-shade-quote/custom-roller-shade-quote.module'
          ).then((m) => m.CustomRollerShadeQuoteModule),
      },
      {
        path: 'perfect-sheer-shade-quote',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/perfect-sheer-shade/perfect-sheer-shade-quote/perfect-sheer-shade-quote.module'
          ).then((m) => m.PerfectSheerShadeQuoteModule),
      },
      {
        path: 'perfect-sheer-shade-contract',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/perfect-sheer-shade/perfect-sheer-shade-contract/perfect-sheer-shade-contract.module'
          ).then((m) => m.PerfectSheerShadeContractModule),
      },
      {
        path: 'perfect-sheer-shade-commission',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/quote-commission/create-quote-commission/quote-commission.module'
          ).then((m) => m.QuoteCommissionModule),
      },
      {
        path: 'perfect-sheer-shade-pricing-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/perfect-sheer-shade/perfect-sheer-shade-pricing-setting/perfect-sheer-shade-pricing-setting.module'
          ).then((m) => m.PerfectSheerShadePricingSettingModule),
      },
      {
        path: 'custom-roller-shade-contract',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/custom-roller-shade/custom-roller-shade-contract/custom-roller-shade-contract.module'
          ).then((m) => m.CustomRollerShadeContractModule),
      },
      {
        path: 'custom-roller-shade-commission',
        data: { roles: [Role.Sales, Role.Admin], permissions: [] },
        loadChildren: () =>
          import(
            './pages/quote-commission/create-quote-commission/quote-commission.module'
          ).then((m) => m.QuoteCommissionModule),
      },
      {
        path: 'custom-roman-shade-pricing-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/custom-roman-shade/custom-roman-shade-pricing/custom-roman-shade-pricing.module'
          ).then((m) => m.CustomRomanShadePricingModule),
      },
      {
        path: 'custom-roman-shade-quote',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/custom-roman-shade/custom-roman-shade-quote/custom-roman-shade-quote.module'
          ).then((m) => m.CustomRomanShadeQuoteModule),
      },
      {
        path: 'custom-roman-shade-contract',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/custom-roman-shade/custom-roman-shade-contract/custom-roman-shade-contract.module'
          ).then((m) => m.CustomRomanShadeContractModule),
      },
      {
        path: 'custom-roman-shade-commission',
        data: { roles: [Role.Sales, Role.Admin], permissions: [] },
        loadChildren: () =>
          import(
            './pages/quote-commission/create-quote-commission/quote-commission.module'
          ).then((m) => m.QuoteCommissionModule),
      },
      {
        path: 'honeycomb-shade-pricing',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/honeycomb-shade/honeycomb-shade-pricing/honeycomb-shade-pricing.module'
          ).then((m) => m.HoneycombShadePricingModule),
      },
      {
        path: 'honeycomb-shade-quote',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/honeycomb-shade/honeycomb-shade-quote/honeycomb-shade-quote.module'
          ).then((m) => m.HoneycombShadeQuoteModule),
      },
      {
        path: 'honeycomb-shade-contract',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/honeycomb-shade/honeycomb-shade-contract/honeycomb-shade-contract.module'
          ).then((m) => m.HoneycombShadeContractModule),
      },
      {
        path: 'honeycomb-shade-commission',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/quote-commission/create-quote-commission/quote-commission.module'
          ).then((m) => m.QuoteCommissionModule),
      },
      {
        path: 'engineered-floor-quote',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/engineered-floor/engineered-floor-quote/engineered-floor-quote.module'
          ).then((m) => m.EngineeredFloorQuoteModule),
      },
      {
        path: 'engineered-floor-contract',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/engineered-floor/engineered-floor-contract/engineered-floor-contract.module'
          ).then((m) => m.EngineeredFloorContractModule),
      },
      {
        path: 'engineered-floor-commission',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/quote-commission/create-floor-commission/floor-commission.module'
          ).then((m) => m.FloorCommissionModule),
      },
      {
        path: 'windows-replacement-quote',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/windows-replacement/windows-replacement-quote/windows-replacement-quote.module'
          ).then((m) => m.WindowsReplacementQuoteModule),
      },
      {
        path: 'windows-replacement-contract',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/windows-replacement/windows-replacement-contract/windows-replacement-contract.module'
          ).then((m) => m.WindowsReplacementContractModule),
      },
      {
        path: 'windows-replacement-commission',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import(
            './pages/quote-commission/create-quote-commission/quote-commission.module'
          ).then((m) => m.QuoteCommissionModule),
      },
      {
        path: 'engineered-floor-pricing-settings',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/engineered-floor/engineered-floor-pricing/engineered-floor-pricing.module'
          ).then((m) => m.EngineeredFloorPricingModule),
      },
      {
        path: 'profile',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [] },
        loadChildren: () =>
          import('./pages/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'ticket',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.tickets] },
        loadChildren: () =>
          import('./pages/job-change/job-change.module').then(
            (m) => m.JobChangeModule
          ),
      },
      {
        path: 'roller-shade-general-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-general/plantation-shutter-general.module'
          ).then((m) => m.PlantationShutterGeneralModule),
      },
      {
        path: 'epoxy-garage-general-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-general/plantation-shutter-general.module'
          ).then((m) => m.PlantationShutterGeneralModule),
      },
      {
        path: 'plantation-shutter-general-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-general/plantation-shutter-general.module'
          ).then((m) => m.PlantationShutterGeneralModule),
      },
      {
        path: 'carpet-installation-general-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-general/plantation-shutter-general.module'
          ).then((m) => m.PlantationShutterGeneralModule),
      },
      {
        path: 'blind-general-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-general/plantation-shutter-general.module'
          ).then((m) => m.PlantationShutterGeneralModule),
      },
      {
        path: 'custom-roller-shade-general-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-general/plantation-shutter-general.module'
          ).then((m) => m.PlantationShutterGeneralModule),
      },
      {
        path: 'perfect-sheer-shade-general-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-general/plantation-shutter-general.module'
          ).then((m) => m.PlantationShutterGeneralModule),
      },
      {
        path: 'custom-roman-shade-general-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-general/plantation-shutter-general.module'
          ).then((m) => m.PlantationShutterGeneralModule),
      },
      {
        path: 'honeycomb-shutter-general-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-general/plantation-shutter-general.module'
          ).then((m) => m.PlantationShutterGeneralModule),
      },
      {
        path: 'engineered-shutter-general-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-general/plantation-shutter-general.module'
          ).then((m) => m.PlantationShutterGeneralModule),
      },
      {
        path: 'windows-replacement-general-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import(
            './pages/plantation-shutter/plantation-shutter-general/plantation-shutter-general.module'
          ).then((m) => m.PlantationShutterGeneralModule),
      },
      {
        path: 'roller-shade-item-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/common-product/common-product.module').then(
            (m) => m.CommonProductModule
          ),
      },
      {
        path: 'epoxy-garage-item-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/common-product/common-product.module').then(
            (m) => m.CommonProductModule
          ),
      },
      {
        path: 'carpet-installation-item-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/common-product/common-product.module').then(
            (m) => m.CommonProductModule
          ),
      },
      {
        path: 'plantation-shutter-item-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/common-product/common-product.module').then(
            (m) => m.CommonProductModule
          ),
      },
      {
        path: 'blind-item-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/common-product/common-product.module').then(
            (m) => m.CommonProductModule
          ),
      },
      {
        path: 'custom-roller-shade-item-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/common-product/common-product.module').then(
            (m) => m.CommonProductModule
          ),
      },
      {
        path: 'perfect-sheer-shade-item-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/common-product/common-product.module').then(
            (m) => m.CommonProductModule
          ),
      },
      {
        path: 'custom-roman-shade-item-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/common-product/common-product.module').then(
            (m) => m.CommonProductModule
          ),
      },
      {
        path: 'honeycomb-shade-item-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/common-product/common-product.module').then(
            (m) => m.CommonProductModule
          ),
      },
      {
        path: 'engineered-floor-item-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/common-product/common-product.module').then(
            (m) => m.CommonProductModule
          ),
      },
      {
        path: 'windows-replacement-item-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/common-product/common-product.module').then(
            (m) => m.CommonProductModule
          ),
      },
      {
        path: 'payment-setting',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/main-general-setting/main-general-setting.module').then(
            (m) => m.MainGeneralSettingModule
          ),
      },
      {
        path: 'general-setting/roller-shade',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/general-setting/general-setting.module').then(
            (m) => m.GeneralSettingModule
          ),
      },
      {
        path: 'general-setting/epoxy-garage',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/general-setting/general-setting.module').then(
            (m) => m.GeneralSettingModule
          ),
      },
      {
        path: 'general-setting/carpet-installation',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/general-setting/general-setting.module').then(
            (m) => m.GeneralSettingModule
          ),
      },
      {
        path: 'general-setting/custom-roller-shade',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/general-setting/general-setting.module').then(
            (m) => m.GeneralSettingModule
          ),
      },
      {
        path: 'general-setting/perfect-sheer-shade',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/general-setting/general-setting.module').then(
            (m) => m.GeneralSettingModule
          ),
      },
      {
        path: 'general-setting/custom-roman-shade',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/general-setting/general-setting.module').then(
            (m) => m.GeneralSettingModule
          ),
      },
      {
        path: 'general-setting/honeycomb-shade',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/general-setting/general-setting.module').then(
            (m) => m.GeneralSettingModule
          ),
      },
      {
        path: 'general-setting/engineered-floor',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/general-setting/general-setting.module').then(
            (m) => m.GeneralSettingModule
          ),
      },
      {
        path: 'terms-and-conditions',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [Permissions.view_settings] },
        loadChildren: () =>
          import('./pages/contract-footers/contract-footers.module').then(
            (m) => m.ContractFootersModule
          )
      },
      {
        path: 'consolidated-job-in-progress/all-job',
        data: { roles: [Role.Sales, Role.Admin,Role.Wholesaler], permissions: [Permissions.view_jobs_in_progress] },
        loadChildren: () =>
          import('./pages/consolidated-job-in-progress/consolidated-job-in-progress.module').then(
            (m) => m.ConsolidatedJobInProgressModule
          ),
      },
      {
        path: 'consolidated-job-in-progress/completed-job',
        data: { roles: [Role.Sales, Role.Admin, Role.Wholesaler], permissions: [Permissions.view_jobs_in_progress] },
        loadChildren: () =>
          import('./pages/consolidated-job-in-progress/consolidated-job-in-progress.module').then(
            (m) => m.ConsolidatedJobInProgressModule
          ),
      },
      {
        path: 'company',
        data: { roles: [Role.Sales, Role.Admin], permissions: [Permissions.view_settings] },
        loadChildren: () => import('./pages/company/company.module').then((m) => m.CompanyModule),
      },
    ],
  },
  { path: 'wholesaler-settings', loadChildren: () => import('./pages/wholesaler-settings/wholesaler-settings.module').then(m => m.WholesalerSettingsModule) },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
